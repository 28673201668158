import {
  Card,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import * as React from 'react';
import { TableChart, Folder } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  clickableListItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
  itemIconImg: {
    width: 32,
  },
}));

export const SpreadsheetsView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const recruiterShortcuts = [
    {
      link: 'https://docs.google.com/spreadsheets/d/1r2G6FKBpjQE_H_BiYDmL7USHlwOlr2v6pLnOPdRdYms/edit#gid=731692981',
      title: 'Data Monitoring',
      description:
        'Get an overview of important metrics such as overdue statuses.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1V_bQkBuV9PcrZlxT8dRQ91_achpwBbuRh56dJQ9ndNk/edit#gid=1938325320',
      title: 'Handover Report',
      description:
        'Create a handover report for your team whilst you are away.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1TCcVt6wFNAeBzD-wfaL4GcAQFSktreD6_foKZ4tJr_I/edit#gid=0',
      title: 'LinkedIn Schedule & Usage',
      description:
        'Schedule times when using LinkedIn Corporate and track your LinkedIn posts and jobs.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1WIc6JBb1C6IfDi9qp1yOwOHeMzAcJZLSE2VgAB_vot8/edit#gid=1078260614',
      title: 'Post Placement Follow Up Sheet',
      description: 'Keep track of followups with candidates and clients.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1hY8873c01-UVOXwMNfUWndAieJyDe2_kDjQCbsKdRTU/edit#gid=1742488263',
      title: 'Project Scouting & Project Hunting Workbook',
      description:
        'Explore job vacancies which Konnekt is currently not actively recruiting for but are advertised on external platforms. This helps you offer candidates a broader range of opportunities beyond our current, available positions.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/19gJfzIwLfH16kgjb2l3_IpCa3mGcjMt4i9k1pg7eY1c/edit#gid=417179507',
      title: 'Pro-Rata Calculator',
      description: 'Calculate the pro-rata amount for a credit note.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1ByWuO3Dsv1AkUWosnIgCIvMAhNPWzjVA1FOENzETLH0/edit#gid=0',
      title: 'Salariesinmalta.com Salary Brackets',
      description:
        'A list of positions and data from Salariesinmalta.com, with detailed salary brackets for each role.',
      icon: <TableChart />,
    },
    {
      divider: true,
    },
    {
      link: 'https://drive.google.com/drive/folders/1cnCRYxUPYrfcN5yWaGIoB40S1UvquMSM',
      title: 'Recruitment Procedures',
      description:
        'A folder containing all our procedures related to recruitment.',
      icon: <Folder />,
    },
  ];

  const commercialShortcuts = [
    {
      title: 'Client Allocation Tool',
      description: 'Assign clients to members within the commercial team.',
      icon: <TableChart />,
      onClick: () => history.push('/commercial-support-utility'),
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1zN2suRAPFlncwlMsHTqHoN2LHma0Kjn2vMi2aheSRYw/edit#gid=685410903',
      title: 'Commercial Support Utility',
      description: 'Quickly create Google Drive Folders for new clients.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1MISaVFfcufcoLioX3YojFbOqfI45y9VIcP2Evhuq8gY/edit#gid=453390159',
      title: 'Challenging Roles for Konnekt',
      description:
        'This sheet outlines vacancies that are difficult to fill due to specific skill requirements, market conditions, or other unique challenges.',
      icon: <TableChart />,
    },
    {
      link: 'https://docs.google.com/spreadsheets/d/1R3unOTroebcTK35Q9DmGRcU8dWGsV8Hd5ptcjs1aldg/edit#gid=26649828',
      title: '15 Day Report',
      description:
        'The 15-day report, showing the statuses of jobs posted on jobsinmalta after 2 weeks.',
      icon: <TableChart />,
    },
    {
      divider: true,
    },
    {
      link: 'https://drive.google.com/drive/folders/0ANZBlJh8P5EdUk9PVA',
      title: 'Client Team Resources',
      description:
        'A folder with several resources used by the Commercial Team.',
      icon: <Folder />,
    },
  ];
  const operationsShortcuts = [
    {
      link: 'https://docs.google.com/spreadsheets/d/1TFBvB2CvLXHQ2SLFBBHXhw0jJwhKJST7k0sM8QYr-Qs/edit#gid=1966919949',
      title: 'Project Assignment Sheet',
      description:
        'Monitor recruiter capacity and assign or manage recruiter projects.',
      icon: <TableChart />,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Recruitment Utility" />
          <List>
            {recruiterShortcuts.map((d, index) =>
              d.divider ? (
                <Divider key={`shortcuts_${index}`} />
              ) : (
                <ListItem
                  key={`shortcuts_${index}`}
                  className={classes.clickableListItem}
                  onClick={() => {
                    window.open(d.link);
                  }}
                >
                  {d.icon && <ListItemIcon>{d.icon}</ListItemIcon>}
                  <ListItemText primary={d.title} secondary={d.description} />
                </ListItem>
              ),
            )}
          </List>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Commercial Utility" />
          <List>
            {commercialShortcuts.map((d, index) =>
              d.divider ? (
                <Divider key={`shortcuts_${index}`} />
              ) : (
                <ListItem
                  key={`shortcuts_${index}`}
                  className={classes.clickableListItem}
                  onClick={() => {
                    if (d.link) {
                      window.open(d.link);
                    } else if (d.onClick) {
                      d.onClick();
                    }
                  }}
                >
                  {d.icon && <ListItemIcon>{d.icon}</ListItemIcon>}
                  <ListItemText primary={d.title} secondary={d.description} />
                </ListItem>
              ),
            )}
          </List>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Operations Utility" />
          <List>
            {operationsShortcuts.map((d, index) => (
              <ListItem
                key={`shortcuts_${index}`}
                className={classes.clickableListItem}
                onClick={() => {
                  window.open(d.link);
                }}
              >
                {d.icon && <ListItemIcon>{d.icon}</ListItemIcon>}
                <ListItemText primary={d.title} secondary={d.description} />
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>
    </Grid>
  );
};
